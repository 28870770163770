import {apiClient as client} from '@/shared/service/api-client';

export default {
  // 企业支付配置
  async getPaySetting(params) {
    return await client.get('/wechat/getPaySetting', params);
  },
  async save(params) {
    return await client.post('/wechat/savePaySetting', params);
  },
  async uploadPrivateKey(params) {
    return await client.post('/wechat/uploadPrivateKey', params);
  },

  // 会话存档配置
  async getChatRecordConf() {
    return await client.get('/conversationArchive/getConf');
  },
  async saveChatRecordConf(params) {
    return await client.post('/conversationArchive/saveConf', params);
  },

  // 系统公众号配置
  // 微信第三方平台授权,生成授权URL
  async getAuthorizationUrl() {
    return await client.get('/wechatPlatform/authorization/acquireAuthorizationUrl');
  },
  // 微信第三方平台授权,保存授权码
  async saveAuthCode(params) {
    return await client.post('/wechatPlatform/authorization/saveAuthCode', params);
  },
  async getAccountAuthInfo() {
    return await client.get('wechatPlatform/authorization/getAuthOfficialAccountInfo');
  },
  async relieveAuth() {
    return await client.post('/wechatPlatform/authorization/relieveAuth');
  },
}
