<template>
  <a-spin :spinning='spinning'>
    <div ref="prompyBox"
         style="min-height: 300px;">

      <a-row>
        <a-col class="text-right"
               flex="80px">
          <div class="flex justify-end items-center">
            公众号
            <a-popover placement="bottomLeft"
                       :getPopupContainer="() => $refs.prompyBox">
              <template #content>
                <p class="prompt-title">为什么要公众号授权？</p>
                <p class="gray"
                   :style="{marginBottom: '24px'}">当客户在访问商机雷达、支付订单等场景时，系统能够获取客户信息。</p>
                <p class="prompt-title">帮助指南</p>
                <p class="gray"
                   :style="{marginBottom: '16px'}">1、如何申请微信服务号？
                  <a target="_blank"
                     href="https://kf.qq.com/faq/120911VrYVrA151013MfYvYV.html">点击查看</a>
                </p>
                <p class="gray">2、如何给服务号申请微信认证？
                  <a target="_blank"
                     href="https://kf.qq.com/faq/161220Brem2Q161220uUjERB.html">点击查看</a>
                </p>
              </template>

              <svg-icon class="ml-4 text-color-999"
                        style="font-size: 16px;"
                        type="iconyiwen"></svg-icon>

            </a-popover>

          </div>

        </a-col>

        <a-col v-if="!authInfo.isAuth">
          <a-button class="ml16"
                    type="link"
                    @click="skipAuthorizationUrl">去授权</a-button>
        </a-col>

        <a-col v-else>
          <div class="auth__container">
            <span class="mb16">
              <span :style="{minWidth: '100px'}">{{authInfo.name}}</span>
              <a-button class="ml16"
                        type="link"
                        @click="skipAuthorizationUrl">重新授权</a-button>
              <a-popconfirm title="解除绑定后不可撤销，确认是否解除授权？"
                            ok-text="解除"
                            cancel-text="取消"
                            @confirm="relieveAuth">
                <a-button class="ml16"
                          type="link">解除授权</a-button>
              </a-popconfirm>
            </span>
            <a-image v-if="authInfo.headImg"
                     class="mb2"
                     :src="authInfo.headImg"
                     alt="公众号图片"
                     width="100px" />
            <img v-else
                 class="mb2"
                 src="@/assets/ErrorImg.png"
                 alt="公众号容错图片"
                 style="width: 100px">
            <span class="gray text-12">您已获得服务号授权，如使用过程中发现异常，请重新授权。</span>
          </div>
        </a-col>
      </a-row>
    </div>

  </a-spin>

</template>

<script>
import _ from "lodash";
import { defineComponent, ref, reactive, onMounted } from "vue";
import { Popover, Image, Popconfirm } from "ant-design-vue";
import { useRoute } from "vue-router";

import SvgIcon from "@/components/SvgIcon";
import wechatSettingApi from "@/service/api/wechatSetting";

export default defineComponent({
  name: "OfficialAccount",

  components: {
    APopover: Popover,
    APopconfirm: Popconfirm,
    AImage: Image,
    SvgIcon,
  },

  setup() {
    const spinning = ref(true);

    const authInfo = reactive({
      isAuth: 0,
      name: "",
      headImg: "",
      authUrl: "",
    });

    async function getAuthInfo() {
      await wechatSettingApi.getAccountAuthInfo().then((res) => {
        _.assign(authInfo, res);
        spinning.value = false;
      });
    }
    async function getAuthorizationUrl() {
      authInfo.authUrl = await wechatSettingApi.getAuthorizationUrl();
    }

    const route = new useRoute();
    const { auth_code, expires_in } = route.query;

    async function saveAuthCode() {
      await wechatSettingApi.saveAuthCode({
        authCode: auth_code,
        authCodeExpiryDate: expires_in,
      });
      getAuthInfo();
    }

    async function relieveAuth() {
      await wechatSettingApi.relieveAuth();
      getAuthInfo();
    }

    function skipAuthorizationUrl() {
      window.location.href = authInfo.authUrl;
    }

    getAuthInfo();
    onMounted(async () => {
      await getAuthorizationUrl();
      if (auth_code && expires_in) {
        saveAuthCode();
      }
    });
    return {
      spinning,
      authInfo,
      relieveAuth,
      skipAuthorizationUrl,
    };
  },
  methods: {},
});
</script>
<style lang='less' scoped>
:deep(.ant-popover-inner-content) {
  padding: @spacing-8x @spacing-4x;

  width: 600px;

  .prompt-title {
    margin-bottom: @spacing-4x;
    .text-16();
    .text-bold();
    color: @gray-darker;
  }
}

.icon-yiwen {
  font-size: 20px;
  margin-left: 4px;
}

.ant-row {
  align-items: baseline;

  .auth__container {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
  }
}
</style>
